exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".gaussian_grid-container_fTvlT {\n    border: 1px solid lightgrey;\n    border-radius: 7px;\n    display: grid;\n    grid-template-columns: repeat(5, 1fr);\n    margin: 30px auto;\n    padding: 10px 20px;\n    width: 500px;\n    background-color: #f0f0f0;\n  }\n  \n  @media (max-width: 768px) {\n    .gaussian_grid-container_fTvlT {\n      width: 300px;\n      padding: 10px 5px;\n    }\n  }\n", ""]);

// exports
exports.locals = {
	"grid-container": "gaussian_grid-container_fTvlT",
	"gridContainer": "gaussian_grid-container_fTvlT"
};